import { adapter as cardAdapter } from '@molecules/Card';
import { adapter as influencerTaglineAdapter } from '@molecules/InfluencerTagline';
import { adapter as relatedCardsAdapter } from '@organisms/RelatedCards';
import { adapter as recipeSelectorAdapter } from '@molecules/RecipeSelector';
import { adapter as influencersAdapter } from '@molecules/InfluencerProfile';
import { adapter as muscleToolAdapter } from '@organisms/MuscleTool';
import { adapter as calculatorPushAdapter } from '@molecules/CalculatorPush';
import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

const getContentObj = (title, subtitle, cards, hiddenLabel, visibleLabel) => ({
  title,
  subtitle,
  cards: cards.map((card) => cardAdapter(card)),
  hiddenLabel,
  visibleLabel,
});

const getMiniTool = (data) => {
  if (data === null) {
    return null;
  }

  switch (data.__typename) {
    case 'ContentfulRecipeSelector':
      return { ...recipeSelectorAdapter(data), type: data.__typename };
    default:
      return null;
  }
};

export default ({
  introTitle,
  introDescription,
  influencers,
  topContentTitle,
  topContentSubtitle,
  topContent,
  topContentHiddenLabel,
  topContentVisibleLabel,
  influencersIntroTitle,
  influencersIntroDescription,
  miniTool,
  bottomContentTitle,
  bottomContentSubtitle,
  bottomContent,
  bottomContentHiddenLabel,
  bottomContentVisibleLabel,
  relatedContentSubtitle,
  relatedContent,
  relatedContentBackgroundDesktop,
  relatedContentBackgroundMobile,
  muscleTool,
  calculatorPush,
}) => ({
  intro: {
    title: introTitle,
    description: introDescription,
    influencers: influencers.map((influencer) => ({
      ...influencerTaglineAdapter(influencer),
      id: influencer.id,
    })),
  },
  topContent: getContentObj(
    topContentTitle,
    topContentSubtitle,
    topContent,
    topContentHiddenLabel,
    topContentVisibleLabel
  ),
  influencers: influencers.map(influencersAdapter),
  influencersIntro: {
    title: influencersIntroTitle,
    description: influencersIntroDescription,
  },
  miniTool: getMiniTool(miniTool),
  bottomContent: getContentObj(
    bottomContentTitle,
    bottomContentSubtitle,
    bottomContent,
    bottomContentHiddenLabel,
    bottomContentVisibleLabel
  ),
  relatedContent: {
    background: {
      mobile: getImageFields(relatedContentBackgroundMobile, IMG_TYPES.fluid),
      desktop: getImageFields(relatedContentBackgroundDesktop, IMG_TYPES.fluid),
    },
    carousel: relatedCardsAdapter({
      ...relatedContent,
      subtitle: relatedContentSubtitle,
    }),
  },
  muscleTool: muscleTool && muscleToolAdapter(muscleTool),
  calculatorPush: calculatorPush && calculatorPushAdapter(calculatorPush),
});
