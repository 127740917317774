import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import Hero, { adapter as heroAdapter } from '@organisms/Hero';
import PromotionalOverlay from '@organisms/PromotionalOverlay';
import { getSettings, getPageMeta } from '@utils';
import Program, { adapter as programAdapter } from '@organisms/Program';
import trackEvent from './trackEvent';

const ProgramPage = ({ location, data, pageContext, path }) => {
  // Grab settings from the data, which is the result from the gql query below
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulProgram;
  const pageSeo = getPageMeta(page.seo, settings.brandName);
  const promotionalOverlayProps = settings?.promotionalOverlay;

  const [programHeroImage, setProgramHeroImage] = useState(null);

  useEffect(() => {
    if (location?.state?.state === undefined) {
      setProgramHeroImage(
        JSON.parse(localStorage.getItem(`program-hero-image${path}`))
      );
    } else if (location?.state?.state) {
      setProgramHeroImage(location.state.state);
      localStorage.setItem(
        `program-hero-image${path}`,
        JSON.stringify(location.state.state)
      );
    }
  }, [location?.state?.state, path]);

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  if (programHeroImage) {
    page.hero.imageMobile.fluid = programHeroImage;
    page.hero.imageDesktop.fluid = programHeroImage;
  }

  const heroProps = heroAdapter(page.hero);

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} />
      <Hero {...heroProps} path={path} pageId={pageContext.id} />
      <Program {...programAdapter(page)} id={pageContext.id} />
      {!!promotionalOverlayProps && (
        <PromotionalOverlay {...promotionalOverlayProps} />
      )}
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getProgramData($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    contentfulProgram(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...ProgramPageComponents
    }
  }
`;

ProgramPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default ProgramPage;
