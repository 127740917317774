import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  RelatedCardsCarousel,
  RelatedCardsAccordion,
} from '@organisms/RelatedCards';
import InfluencerTagline from '@molecules/InfluencerTagline';
import RecipeSelector from '@molecules/RecipeSelector';
import TextModule from '@atoms/TextModule';
import InfluencersProfile from '@molecules/InfluencerProfile';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import MuscleTool from '@organisms/MuscleTool';
import CalculatorPush from '@molecules/CalculatorPush';

const ProgramContainer = styled.section`
  ${({ theme }) => css`
    > * + * {
      margin-top: ${theme.spacing(8)};

      ${theme.mediaquery.sm(css`
        margin-top: ${theme.spacing(10)};
      `)}
    }
  `}
`;

const IntroTitle = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    color: ${theme.colors.white};
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleL};
      padding: 0 calc(var(--outer-gap) + var(--col) * 5);
      text-align: center;
    `)}
  `}
`;

const IntroDescription = styled(TextModule)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(2)};

    ${theme.mediaquery.sm(css`
      text-align: center;
    `)}
  `}
`;

const InfluencersTaglineContainer = styled.ul`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 calc(var(--outer-gap) + var(--col) * 5);
    `)}
  `}
`;

const StyledInfluencerTagline = styled(InfluencerTagline)`
  ${({ theme }) => css`
    &:not(:first-child) {
      margin-top: ${theme.spacing(2)};
    }

    ${theme.mediaquery.sm(css`
      margin: ${theme.spacing(1)};

      &:not(:first-child) {
        margin-top: 0;
      }
    `)}
  `}
`;

const IntroInfluencersTitle = styled(IntroTitle)`
  ${({ theme }) =>
    theme.mediaquery.sm(css`
      text-align: left;
      padding-right: calc(var(--outer-gap) + var(--col) * 7);
      padding: 0 calc(var(--outer-gap) + var(--col) * 3);
    `)}
`;

const IntroInfluencersDescription = styled(IntroDescription)`
  ${({ theme }) => css`
    display: block;
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      text-align: left;
      padding-left: calc(var(--outer-gap) + var(--col) * 3);
      padding-right: 0;
      width: calc(var(--col) * 12 - var(--inner-gap));
      box-sizing: content-box;
    `)}
  `}
`;

const RelatedContentContainer = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-rows: auto ${theme.spacing(25)} auto;
    position: relative;
  `}
`;

const StyledImgWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

const StyledImg = styled(Img)`
  position: relative;
  grid-column: 1;
  grid-row: 1 / span 2;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.black};
    opacity: 0.4;
  }
`;

const StyledRelatedCardsCarousel = styled(RelatedCardsCarousel)`
  ${({ theme }) => css`
    padding-top: 50px;
    grid-column: 1;
    grid-row: 2 / span 2;

    ${theme.mediaquery.md(css`
      padding-top: 100px;
    `)}
  `}
`;

const Program = ({
  id,
  intro,
  topContent,
  influencers,
  influencersIntro,
  miniTool,
  bottomContent,
  relatedContent,
  muscleTool,
  calculatorPush,
}) => {
  const getMiniToolComponent = () => {
    const { __typename, ...rest } = miniTool;
    switch (__typename) {
      case 'ContentfulRecipeSelector':
        return <RecipeSelector {...rest} />;
      default:
        return null;
    }
  };

  return (
    <ProgramContainer>
      <div>
        <IntroTitle>{intro.title}</IntroTitle>
        <IntroDescription doc={intro.description} enableRenderer />
        <InfluencersTaglineContainer>
          {intro.influencers.map((influencer) => (
            <StyledInfluencerTagline
              key={influencer.id}
              {...influencer}
              forwardedAs="li"
            />
          ))}
        </InfluencersTaglineContainer>
      </div>
      <div>
        <IntroInfluencersTitle>{influencersIntro.title}</IntroInfluencersTitle>
        <IntroInfluencersDescription
          doc={influencersIntro.description}
          enableRenderer
        />
        <InfluencersProfile id={id} influencers={influencers} />
      </div>
      <RelatedCardsAccordion {...topContent} />
      <RelatedCardsAccordion {...bottomContent} />
      {miniTool && getMiniToolComponent()}
      {calculatorPush && <CalculatorPush {...calculatorPush} />}
      <RelatedContentContainer>
        <StyledImgWrapper>
          <StyledImg
            type={IMG_TYPES.fluid}
            fit={IMG_FITS.cover}
            small={relatedContent.background.mobile}
            medium={relatedContent.background.desktop}
          />
        </StyledImgWrapper>
        <StyledRelatedCardsCarousel
          {...relatedContent.carousel}
          biggerCardsTitle
        />
      </RelatedContentContainer>
      {muscleTool && <MuscleTool {...muscleTool} />}
    </ProgramContainer>
  );
};

Program.propTypes = {
  id: PropTypes.string.isRequired,
  intro: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    influencers: PropTypes.arrayOf(PropTypes.shape(InfluencerTagline.propTypes))
      .isRequired,
  }).isRequired,
  topContent: PropTypes.shape(RelatedCardsAccordion.propTypes),
  influencersIntro: {
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
  }.isRequired,
  influencers: PropTypes.array.isRequired,
  miniTool: PropTypes.oneOf([PropTypes.shape(RecipeSelector.propTypes)]),
  bottomContent: PropTypes.shape(RelatedCardsAccordion.propTypes),
  relatedContent: PropTypes.shape({
    background: PropTypes.object.isRequired,
    carousel: PropTypes.shape(RelatedCardsCarousel.propTypes),
  }),
  muscleTool: PropTypes.shape(MuscleTool.propTypes),
  calculatorPush: PropTypes.shape(CalculatorPush.propTypes),
};

export default Program;
